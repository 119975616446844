<template>
   <div class="paybox" >
              <div class="logo"><img :src="logoUrl" alt=""></div>
              <div class="input"><span>{{price}}</span><span class="guangbiao"></span></div>
              <div class="paybtn" :class="payEnv.title" @click="submit">{{payEnv.name}}</div>

              <Msg :msg='msg' :icon="icon" v-if="showmsg" /> 

             <div class="keyword">
                          <div class="down" style="display:none"><img src="../../assets/img/down.png" alt=""></div>
                          <div class="numberbox">
                                  <div class="itemnum" @click="btnnum('1')"><span class="borderr">1</span></div>
                                  <div class="itemnum" @click="btnnum('2')"><span class="borderr">2</span></div>
                                  <div class="itemnum" @click="btnnum('3')"><span >3</span></div>
                                  <div class="itemnum" @click="btnnum('4')"><span class="borderr">4</span></div>
                                  <div class="itemnum" @click="btnnum('5')"><span class="borderr">5</span></div>
                                  <div class="itemnum" @click="btnnum('6')"><span>6</span></div>
                                  <div class="itemnum" @click="btnnum('7')"><span class="borderr">7</span></div>
                                  <div class="itemnum" @click="btnnum('8')"><span class="borderr">8</span></div>
                                  <div class="itemnum" @click="btnnum('9')"><span >9</span></div>
                                  <div class="itemnum keycolor" @click="btnnum('.')"><span class="borderr point"></span></div>
                                  <div class="itemnum" @click="btnnum('0')"><span class="borderr">0</span></div>
                                  <div class="itemnum keycolor" @click="btnnum('delete')"><span style="padding:0"><img class="delete" src="../../assets/img/delete.png" alt=""></span></div>
                          </div>
              </div>
    </div>    
</template>
<style>
.paybox{margin: auto;padding:30px; }
.paybox .logo{    width: 100px;    height: 100px;    margin: auto;    border-radius: 4px;    overflow: hidden;}
.paybox .paybtn{ text-align: center; padding: 14px 0; background: #ffdf20; margin: 20px 0; border-radius: 6px;    color: #333;font-size: 16px;}
.paybox .paybtn.others{background:#c3c3c3}
.paybox .input{ width: 100%; margin: 20px 0;    overflow: hidden;    border-radius: 6px;    border: 1px solid #999;  padding: 12px; font-size: 18px; }
.paybox  .placeholder{color: #999;background: #efefef;}
.paybox .paybtn:active{opacity: 0.5;}
.keyword{position: fixed;left: 0;bottom: 0;right: 0;font-size: 18px;}
.keyword .down{background:#efefef}
.keyword .down img{    width: 20px;    height: 20px;    display: block;    margin: auto;}
.keyword .numberbox .itemnum{width: 33.33%;float: left;text-align: center;border-top: 1px solid #efefef;height: 54px;line-height: 54px;font-size: 18px;}
.keyword .numberbox .itemnum:active{background: #efefef;opacity: 0.5;}
.keyword .numberbox .itemnum span{display: block;}
.keyword .numberbox .itemnum span .delete{width: 26px;height: 25px;margin-top:14px;}
.borderr{border-right: 1px solid #efefef;}
.keycolor{background: #efefef;}
.point{    width: 3px;    height: 3px;    background: #000;    margin: 24px auto;border-radius: 50%;}
.guangbiao {display: -webkit-inline-box;  width: 4px;height: 13px;  margin: 0 auto;border-right: 1px solid #000;animation: cursorImg 1s infinite steps(1, start);}
 @keyframes cursorImg {0%,100% {opacity: 0;}50% {opacity: 1;}}

</style>
<script>
import Msg from '../../components/msg'
import { getpay,getpayPre} from '../../request/api';
export default {
    name: 'Paybox',
    components: {Msg},
    data() {
      return {
           msg:'',
           logoUrl:'',
           icon:false,
           showmsg:false,
           price:'',
           payEnv:{title:'weixin',name:'微信支付',id:3}
      }
    },
    mounted(){
         document.title = '舒克美鞋';
         this.IsWeixinOrAlipay();
         this.storeId=this.$route.query.storeId;
         this.merchantsId=this.$route.query.merchantsId;
         this.codeId=this.$route.query.codeId;
         this.getpayPre()

    },
    methods: {
       getpayPre(){
             getpayPre({'merchantsId':this.merchantsId}).then(res=>{
                 if(res.code==200){
                          document.title = res.result.title
                          this.logoUrl = res.result.logoUrl
                 }
                           
             })
       },
       submit(){
           if(this.flag){ return false;}
           if(this.payEnv.id=='others'){ return false;}
           let that = this; that.icon = false;
           let data = {
               money:that.price?parseFloat(that.price):0.01,
               storeId:that.storeId,
               merchantsId:that.merchantsId,
               codeId:that.codeId,
               payWay:that.payEnv.id,
               paySubWay:6,//h5
           };
 
           if(!data.money){
                 that.showmsg=true;
                 that.msg='请输入金额';
                 setTimeout(function(){ that.showmsg=false; },1000)
                 return false;
           }
            that.showmsg=true;
            that.icon = true;
            that.msg='支付中';
            that.flag = true;
       
     
           getpay(data).then(res=>{
                that.flag = false;
               if(res.code==200){
                     that.showmsg=false;
                     window.location.href = res.result.qr_code;

               }else{
                    that.showmsg=true;
                    that.msg='网络错误';
                    setTimeout(function(){ that.showmsg=false; },1000)
               }
           })
       },
       btnnum(num){
           let index = -1;
           if(this.price.length>0){
                  index = this.price.indexOf('.');
                  if(index>0&&num!='delete'){
                     if(this.price[index+2]){ 
                          //小数点后两位有值 禁止输入
                          return true;
                     }
                  }
                  if(this.price.length>7&&num!='delete'){return true;}
           }
             
               switch(num){
                    case '0':
                       if(this.price==''){this.price='0.'; }
                       else if(this.price!='0'&&this.price!='0.0'){this.price+=num; }
                       break;
                    case '.':
                        if(this.price.length>0){
                            if(this.price.indexOf('.') == -1){ this.price+=num; }
                        }else{    this.price = '0.'; }
                        break;
                    case 'delete':
                         if(this.price.length>0){
                            this.price =  this.price.substring(0,this.price.length-1);
                         }
                         break;
                    default:
                        this.price+=num; 
                       
               }
       },

        IsWeixinOrAlipay(){
                let payEnv={};
                // 判断微信还是支付宝
                let ua = window.navigator.userAgent;
                if (/MicroMessenger/.test(ua)) {
                    payEnv.title = 'weixin';
                    payEnv.name = '微信支付';
                    payEnv.id = 3;
                } else if (/AlipayClient/.test(ua)) {
                    payEnv.title = 'alipay';
                    payEnv.name = '支付宝支付';
                    payEnv.id = 2;
                } else {
                    payEnv.title = 'others';
                    payEnv.name = '请使用微信或支付宝扫码';
                    payEnv.id='others';
                }
                this.payEnv=payEnv;
        }
    }
}
</script>